// const BaseUrl2 = "https://gokulnair.com/levelup_backend/api/";
const BaseUrl =
  "https://panel.ravinimavat.com/polomeds/api/";

export const products_url = BaseUrl + "get_products";
export const cheke_state = BaseUrl + "cheke_state";
export const add_user_check_product = BaseUrl + "createOrder_test";
export const contact_details_step_25 = BaseUrl + "contact_details_step_25";
export const get_product_details = BaseUrl + "get_product_details";
export const get_url_data = BaseUrl + "get_url_data";
export const createOrder_test = BaseUrl + "createOrder_test";
export const check_coupons = BaseUrl + "check_coupons";
